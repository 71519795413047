<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Alterar senha</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                maxlength="12"
                v-model="user.oldPassword"
                :type="show1 ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                label="Senha atual"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                maxlength="12"
                v-model="user.newPassword"
                :type="show2 ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                label="Nova senha"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2 = !show2"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                maxlength="12"
                v-model="user.retryPassword"
                :type="show3 ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                label="Repita nova senha"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show3 = !show3"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeModal()"> FECHAR </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save()"
      >
        ALTERAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  name: "ModalChangePassword",
  props: {
    userId: Object,
  },
  data: () => ({
    show1: false,
    show2: false,
    show3: false,
    valid: false,
    user: {},
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => (v && v.length >= 6) || "Minimo 6 caracteres!",
    },
  }),
  methods: {
    closeModal() {
      this.user = {};
      this.$emit("closeModal");
    },
    finnalyModal() {
      this.$emit("finnalyModal");
    },
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      const url = `${baseApiUrl}/users/`;
      this.user.id = this.userId.id;
  
      axios["put"](url, this.user)
        .then(() => {
          alert("Senha alterada com sucesso")
          this.finnalyModal();
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data);
          }
        });
    },
  },
  computed: {
    verifyAlteration() {
      return (
        this.user.expirationPrAt ||
        this.user.expirationAt ||
        this.user.blocked != undefined
      );
    },
  },
};
</script>

<style>
</style>